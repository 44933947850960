import React from 'react';
import PropTypes from 'prop-types';

import Collection from '../Collection';
import Promo from '../Promo';
import { promoShape } from '../shapes';

if (process.env.CLIENT) {
  require('./StandardCollection.scss'); // eslint-disable-line global-require
}

const StandardCollection = ({
  id,
  containerClassName,
  maxCollectionSize,
  promos,
  title,
  TitleElement,
  standard,
}) => (
  <Collection
    containerClassName={containerClassName}
    collectionClassName={standard ? 'standard' : ''}
    id={id}
    TitleElement={TitleElement}
    title={title}
  >
    {promos
      .slice(0, maxCollectionSize)
      .map(
        ({
          id: promoId,
          imageId,
          title: promoTitle,
          synopsis,
          subtitle,
          type,
          url,
          servingsText = '',
          prepTime = '',
          cookingTime = '',
        }) => (
          <div key={promoId} className="gel-layout__item gel-1/2 gel-1/4@xl">
            <Promo
              collectionId={id}
              collectionTitle={title}
              id={promoId}
              imageId={imageId}
              synopsis={synopsis}
              subtitle={subtitle}
              title={promoTitle}
              type={type}
              url={url}
              servingsText={servingsText}
              prepTime={prepTime}
              cookingTime={cookingTime}
              standard={standard}
            />
          </div>
        )
      )}
  </Collection>
);

StandardCollection.propTypes = {
  id: PropTypes.string,
  containerClassName: PropTypes.string,
  maxCollectionSize: PropTypes.number,
  promos: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  title: PropTypes.string,
  TitleElement: PropTypes.node,
  standard: PropTypes.bool,
};

StandardCollection.defaultProps = {
  id: undefined,
  containerClassName: undefined,
  maxCollectionSize: 16,
  title: '',
  TitleElement: 'h2',
  standard: true,
};

export default StandardCollection;
