import React from 'react';
import PropTypes from 'prop-types';
import compact from 'lodash/compact';

const Paragraphs = ({ text, className, modifyParagraph }) => (
  <>
    {compact(text.split(/\r\n|\n/).map(x => x.trim())).map((paragraph, i) => (
      <p
        key={`paragraph-${i}`} // eslint-disable-line react/no-array-index-key
        className={className}
        dangerouslySetInnerHTML={{ __html: modifyParagraph(paragraph) }}
      />
    ))}
  </>
);

Paragraphs.defaultProps = {
  className: '',
  modifyParagraph: x => x,
};

Paragraphs.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  modifyParagraph: PropTypes.func,
};

export default Paragraphs;
