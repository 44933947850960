/* eslint-disable react/jsx-curly-brace-presence */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

if (process.env.CLIENT) {
  require('./SeeAllRecipesLink.scss'); // eslint-disable-line global-require
}

const SeeAllRecipesLink = ({ body, totalCount, href, className }) => (
  <div className={classNames('see-all-recipes-link__container', className)}>
    {'See '}
    <a className="see-all-recipes-link" href={href}>
      {body}
    </a>
    {totalCount !== undefined && ` (${totalCount})`}
  </div>
);

SeeAllRecipesLink.propTypes = {
  body: PropTypes.string.isRequired,
  totalCount: PropTypes.number,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
};

SeeAllRecipesLink.defaultProps = {
  className: '',
  totalCount: undefined,
};

export default SeeAllRecipesLink;
