import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Paragraphs from './Paragraphs';

if (process.env.CLIENT) {
  require('./ImageHero.scss'); // eslint-disable-line global-require
}

export const ImageHero = ({ title, description, imageUrl, ichefBasePath }) => {
  const finalImageUrl = /^http/.test(imageUrl) ? imageUrl : `${ichefBasePath}${imageUrl}`;
  return (
    <>
      {title && (
        <div className="image-hero__title-wrap gel-wrap">
          <h1 className="gel-trafalgar image-hero__name">{title}</h1>
        </div>
      )}
      {imageUrl && (
        <div className="gel-wrap image-hero__image-wrap">
          <div className="gel-1/1 gel-layout--center image-hero__image-container">
            <div className="gel-1/1 gel-10/12@xxl gel-layout__item image-hero__image-layout">
              <div
                className={classNames('image-hero__image', { 'box-shadow-bottom': !!description })}
                style={{
                  backgroundImage: `url(${finalImageUrl})`,
                }}
              />
            </div>
          </div>
        </div>
      )}
      {description && (
        <div
          className={classNames('gel-wrap', 'image-hero__info-wrap', {
            'zero-top': !imageUrl,
          })}
        >
          <div
            className={classNames('image-hero__info-container', {
              'gel-layout': !imageUrl,
              'gel-layout--center': imageUrl,
            })}
          >
            <div className="gel-1/1 gel-10/12@xxl gel-layout__item">
              <div className="gel-layout">
                <div className="gel-1/1 gel-layout__item gel-pica image-hero__info">
                  <Paragraphs text={description} className="image-hero__description" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ImageHero.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  imageUrl: PropTypes.string,
  ichefBasePath: PropTypes.string.isRequired,
};

ImageHero.defaultProps = {
  description: undefined,
  imageUrl: undefined,
  title: undefined,
};

export default connect(state => ({
  ichefBasePath: state.pageReducer.ichefBasePath,
}))(ImageHero);
