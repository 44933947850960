import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ExternalLinks from './ExternalLinks';
import ImageHero from '../generic/ImageHero';
import ContentSeparator from '../generic/ContentSeparator';
import AzPage from '../generic/aToZ/Main';
import { Header2 } from '../generic/PageHeader';
import SeeAllRecipesLink from '../generic/SeeAllRecipesLink';
import StandardCollection from '../promo/collections/StandardCollection';
import { promoShape } from '../promo/shapes';
import MetaProperties from '../generic/MetaProperties';

export const Page = ({
  id,
  azCounts,
  biog,
  activeTab,
  displayName,
  imageUrl,
  links,
  items,
  page,
  programmes,
  recipeTotalCount,
  letter,
}) => {
  const upperCaseLetter = letter.toUpperCase();
  const featured = activeTab === 'featured';
  const baseUrl = `/food/chefs/${id}`;

  return (
    <>
      <MetaProperties
        title={featured ? `${displayName} recipes` : `Chefs | ${displayName} | ${upperCaseLetter}`}
        description={
          featured
            ? `Browse the BBC's archive of recipes by ${displayName}`
            : `Browse this page to find all recipes from ${displayName} that begin with ${upperCaseLetter}.`
        }
        imageUrl={imageUrl}
      />
      {(imageUrl || biog) && (
        <ImageHero imageUrl={imageUrl} title={displayName} description={biog} />
      )}
      <ContentSeparator />
      <Header2 title={`Recipes by ${displayName}`} />
      {items.length > 0 && (
        <AzPage
          azCounts={azCounts}
          baseUrl={baseUrl}
          activeTab={activeTab}
          entity="recipes"
          letter={letter}
          page={page}
          promos={items}
          promoContainerClassName="chef-page"
          totalCount={recipeTotalCount}
        />
      )}
      {activeTab === 'featured' && (
        <div className="see-all-recipes__wrap gel-wrap">
          <SeeAllRecipesLink
            body={`all ${displayName} recipes`}
            totalCount={recipeTotalCount}
            href={`/food/search?chefs=${id}`}
          />
        </div>
      )}
      {programmes && programmes.length > 0 && (
        <>
          <ContentSeparator />
          <StandardCollection
            id={`chef-${id}-programmes`}
            title={`Programmes with ${displayName}`}
            promos={programmes}
            maxCollectionSize={programmes.length}
          />
        </>
      )}
      {links && links.length > 0 && (
        <div className="food-body__grey-background">
          <ExternalLinks links={links} />
        </div>
      )}
    </>
  );
};

Page.propTypes = {
  id: PropTypes.string.isRequired,
  azCounts: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  biog: PropTypes.string,
  activeTab: PropTypes.oneOf(['all', 'featured']),
  displayName: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    })
  ).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  page: PropTypes.number,
  programmes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      imageId: PropTypes.string,
      title: PropTypes.string,
      updatedAt: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  recipeTotalCount: PropTypes.number,
  letter: PropTypes.string,
};

Page.defaultProps = {
  azCounts: {},
  biog: undefined,
  activeTab: 'featured',
  displayName: undefined,
  letter: 'a',
  page: 1,
  programmes: undefined,
  recipeTotalCount: undefined,
};

export default connect(({ chefReducer }) => ({
  id: chefReducer.id,
  azCounts: chefReducer.azCounts,
  biog: chefReducer.biog,
  activeTab: chefReducer.activeTab,
  displayName: chefReducer.displayName,
  imageUrl: chefReducer.imageUrl,
  links: chefReducer.links,
  items: chefReducer.items,
  page: chefReducer.page,
  programmes: chefReducer.programmes,
  recipeTotalCount: chefReducer.recipeTotalCount,
  recipeCountForLetter: chefReducer.recipeCountForLetter,
  letter: chefReducer.letter,
}))(Page);
