import React from 'react';
import PropTypes from 'prop-types';

if (process.env.CLIENT) {
  require('./ExternalLinks.scss'); // eslint-disable-line global-require
}

const ExternalLinks = ({ links }) => (
  <div className="gel-wrap external__links__wrap">
    <div className="external__links">
      <h2 className="gel-double-pica external__links-head">Elsewhere on the web</h2>
      <div className="gel-layout external__links-body">
        {links.map(link => (
          <div
            key={link.href}
            className="gel-1/1 gel-1/2@m gel-1/3@l gel-layout__item external__link-container"
          >
            <a className="external__link" href={link.href}>
              <span className="external__link__body">{link.title}</span>
            </a>
          </div>
        ))}
      </div>
    </div>
  </div>
);

ExternalLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ExternalLinks;
