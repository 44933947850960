import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import TabBar from '../tabBar/TabBar';
import Tab from '../tabBar/Tab';
import AzKeyboard from './AzKeyboard';
import StandardCollection12Promos from '../../promo/collections/StandardCollection12Promos';
import { promoShape } from '../../promo/shapes';
import Pagination from '../pagination/Pagination';
import PaginationSummary from '../pagination/PaginationSummary';

export const AToZ = ({
  azCounts,
  baseUrl,
  activeTab,
  domain,
  entity,
  letter,
  page,
  promos,
  promoContainerClassName,
  anchor,
  totalCount,
}) => (
  <div className="az__body">
    {(activeTab === 'all' || totalCount > 24) && (
      <TabBar>
        <Tab
          id="featured"
          href={`${baseUrl}#${anchor}`}
          entity={entity}
          title={`Featured ${entity}`}
          selected={activeTab === 'featured'}
        >
          Featured
        </Tab>
        <Tab
          id="all"
          href={`${baseUrl}/a-z#${anchor}`}
          entity={entity}
          title={`All ${entity}`}
          selected={activeTab === 'all'}
        >
          All
        </Tab>
      </TabBar>
    )}
    {activeTab === 'all' && (
      <>
        <Helmet>
          <meta property="og:url" content={`${domain}${baseUrl}`} />
          <link rel="canonical" href={`${domain}${baseUrl}`} />
        </Helmet>
        <AzKeyboard baseUrl={baseUrl} selected={letter} azCounts={azCounts} anchor={anchor} />
        <PaginationSummary page={page} totalCount={azCounts[letter]}>
          {entity}
        </PaginationSummary>
      </>
    )}
    {promos && (
      <StandardCollection12Promos
        id={`az-${entity}-${letter}-recipes`}
        promos={promos}
        containerClassName={promoContainerClassName}
        maxCollectionSize={24}
      />
    )}
    {activeTab === 'all' && (
      <Pagination
        page={page}
        pages={Math.ceil(azCounts[letter] / 24)}
        buildUrl={p => `${baseUrl}/a-z/${letter}/${p}#${anchor}`}
      />
    )}
  </div>
);

AToZ.defaultProps = {
  azCounts: {},
  activeTab: 'featured',
  letter: 'a',
  page: 1,
  promoContainerClassName: '',
  anchor: 'featured-content',
};

AToZ.propTypes = {
  azCounts: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  baseUrl: PropTypes.string.isRequired,
  activeTab: PropTypes.oneOf(['all', 'featured']),
  domain: PropTypes.string.isRequired,
  entity: PropTypes.string.isRequired,
  letter: PropTypes.string,
  page: PropTypes.number,
  promos: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  promoContainerClassName: PropTypes.string,
  anchor: PropTypes.string,
  totalCount: PropTypes.number.isRequired,
};

export default connect(({ pageReducer }) => ({
  domain: pageReducer.baseURL,
}))(AToZ);
